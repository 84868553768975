import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import paper, { Path, Layer, Group, Point, Size } from "paper-jsdom";
import { connect } from "react-redux";
import StickerDrawer from "./ToolDrawers/Stickers";
import TextEdit from "./ToolDrawers/TextEdit";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Icons/style.css";
import "./App.css";
import {
  selectTool,
  selectToolBar,
  selectToolDrawer,
  hideDrawer,
  showDrawer,
  changeSettings,
} from "./Actions/tools";

import { updateHistoryState } from "./Actions/history";

//Tools
import Draw from "./Tools/draw";

import Select from "./Tools/select";
import Undo from "./Tools/undo";
import Redo from "./Tools/redo";
import Clear from "./Tools/clear";
import Text from "./Tools/text";
import Sticker from "./Tools/sticker";

import { ActionCreators } from "redux-undo";
import * as Toolbar from "./Styled/ToolBar";
import {
  importSVG,
  exportSVG,
  importSVGBackground,
} from "./svgCommon/functions";
import { Button } from "reactstrap";
// Hook
function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      const eventListener = (event) => savedHandler.current(event);
      element.addEventListener(eventName, eventListener);
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}

const changeZoom = (oldZoom, delta, center, position) => {
  let factor;
  let newZoom;
  let direction;

  factor = 1.05;
  if (delta < 0) {
    newZoom = oldZoom * factor;
    direction = true;
  }
  if (delta > 0) {
    newZoom = oldZoom / factor;
    direction = false;
  }
  let beta = oldZoom / newZoom;
  let offset;

  if (direction) {
    let pc = position.subtract(center);
    offset = position.subtract(pc.multiply(beta)).subtract(center);
  } else {
    let pc = position.subtract(center);
    offset = position.subtract(pc.multiply(beta)).subtract(center);
  }

  return [newZoom, offset, direction];
};

let ratio;

let App = React.memo(
  ({
    selectTool,
    canvasState,
    currentTool,
    undo,
    redo,
    updateHistoryState,
  }) => {
    const CanvasRef = useRef(null);
    const selectToolRef = useRef(null);
    const textEditor = useRef(null);
    const createText = useRef(null);

    const [canvasBounds, setCanvasBounds] = useState({});
    const [stickers, setStickers] = useState(false);
    const [position, setPosition] = useState(false);
    const [textContent, setTextContent] = useState(false);
    const [totalRotation, setTotalRotation] = useState(false);

    const [gesture, setGesture] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(0);
    const [viewport, setViewport] = useState({});
    const [webviewMessage, setWebviewMessage] = useState("Nothing");

    const toolbarRef = useRef(null);

    const [debugMessage, setDebugMessage] = useState("test");

    const exportSVGlocal = () => {
      exportSVG().then((SVG) => {
        console.log(SVG);
      });
    };

    const receiveMessage = (e) => {
      if (e.data) {
        let json = JSON.parse(e.data);
        switch (json.type) {
          case "SVGrequest":
            exportSVG().then((SVG) => {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({ type: "SVGreturn", message: SVG })
              );
            });
            break;
          case "BACKGROUND_SVG":
            let {svg, wipe, reload} = json.message;
            importSVGBackground(svg, wipe, reload);
            break;
          case "SVGimport":
            importSVG(json.message).then((_) => {
              pickSelectTool();
              updateState();
            });
            break;
          case "keyboardShow":
            setViewport(json.message);
            break;
          case "keyboardHide":
            setViewport(json.message);
            break;
          case "error":
            document.postMessage(
              JSON.stringify({ type: "alert", message: json.message })
            );
            break;
          default:
            return;
        }
      }
    };

    useEffect(() => {
      // Firefox
      window.addEventListener(
        "gesturestart",
        (e) => {
          e.preventDefault();
          setGesture(true);
        },
        false
      );
      window.addEventListener(
        "gesturechange",
        (e) => {
          setGesture(true);
        },
        false
      );
      window.addEventListener("gestureend", () => setGesture(false), false);

      document.body.addEventListener(
        "touchmove",
        function (event) {
          //if (event.source == document.body)
          event.preventDefault();
        },
        false
      );

      const {
        width,
        height,
        top,
        left,
      } = CanvasRef.current.getBoundingClientRect();
      setCanvasBounds({ width, height, top, left });

      ratio = width / 500;
      paper.setup("myCanvas");
      paper.settings.handleSize = 20;
      paper.settings.applyMatrix = false;
      paper.view.center = new Point(250, 250);
      paper.view.autoUpdate = true;
      paper.view.scaling = ratio;

      //CROP RECTANGLE
      const point = new Point(2, 2);
      const size = new Size(496, 496);
      const crop = new Path.Rectangle(point, size);
      crop.strokeWidth = 1.5;
      crop.strokeColor = "#000000";
      crop.opacity = 0.5;
      crop.dashArray = [4, 2];
      crop.strokeScaling = false;

      const layer = new Layer({ name: "drawLayer" });
      layer.activate();
    }, []);

    // const MouseWheelHandler = useCallback(e => {
    //   // Update coordinates
    //   var e = window.event || e; // old IE support
    //   const delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
    //   const mousePosition = new paper.Point(e.offsetX, e.offsetY);
    //   const viewPosition = paper.view.viewToProject(mousePosition);
    //   let _ref1 = changeZoom(
    //     paper.view.zoom,
    //     delta,
    //     paper.view.center,
    //     viewPosition
    //   );
    //   const newZoom = _ref1[0];
    //   const offset = _ref1[1];
    //   const zoomIn = _ref1[2];
    //   paper.view.zoom = Math.max(newZoom, ratio);
    //   if (newZoom < ratio) {
    //   } else if (newZoom < ratio * 0) {
    //   } else {
    //     paper.view.center = paper.view.center.add(offset);
    //   }
    //   //currentScaling = false;
    //   paper.project.activeLayer.strokeWidth = newZoom;
    //   setZoomLevel(newZoom);
    //   return false;
    // });

    useEventListener("message", receiveMessage, window);
    useEventListener("message", receiveMessage, document);


    const updateState = () => {
      newJot();
      window.dispatchEvent(new CustomEvent("undo", {}));
      let state = paper.project.exportJSON();
      if (canvasState.present !== state) {
        updateHistoryState(state);
      }
    };

    const svgRequest = () => {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "SVGrequest", message: "" })
      );
    };

    const undoState = () => {
      undo();
      paper.project.clear();
      if (canvasState.past.length > 0) {
        paper.project.importJSON(canvasState.past[canvasState.past.length - 1]);
      } else {
        paper.project.importJSON(canvasState.present);
      }
    };

    const redoState = () => {
      redo();
      paper.project.clear();
      canvasState.future.length > 0
        ? paper.project.importJSON(canvasState.future[0])
        : paper.project.importJSON(canvasState.present);
    };

    const openStickers = () => {
      setStickers(true);
    };

    const pickSelectTool = () => {
      selectToolRef.current.onClick();
    };

    const windowPostMessage = (message) => {
      setTimeout(
        () =>
          window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage(JSON.stringify(message)),
        50
      );
    };

    const showButton = () => {
      windowPostMessage({ type: "ButtonShow", message: 500 });
    };

    const hideButton = () => {
      windowPostMessage({ type: "ButtonHide", message: 500 });
    };

    const newJot = () => {
      windowPostMessage({ type: "NewJot", message: 500 });
    };

    const clearJot = () => {
      windowPostMessage({ type: "ClearJot", message: 500 });
    };


    return (
      <div id={"app1"} className="App" style={styles.app}>
        <Toolbar.Container animated={false} ref={toolbarRef}>
          <Toolbar.Left>
            <Draw
              gesture={gesture}
              selected={currentTool === "Draw"}
              selectTool={selectTool}
              updateHistoryState={updateState}
            />
            <Select
              zoom={zoomLevel / ratio || 1}
              ref={selectToolRef}
              selected={currentTool === "Select"}
              selectTool={selectTool}
              updateHistoryState={updateState}
            />
            <Sticker
              selected={currentTool === "Sticker"}
              selectTool={selectTool}
              openStickers={openStickers}
            />
            <Text
              selected={currentTool === "text"}
              selectTool={selectTool}
              texteditor={textEditor}
              canvas={CanvasRef}
            />
          </Toolbar.Left>
          <Toolbar.Right>
            <Undo undoState={undoState} currentState={canvasState} />
            <Redo redoState={redoState} currentState={canvasState} />
            <Clear clearJot={clearJot} />
          </Toolbar.Right>
        </Toolbar.Container>

        <div style={styles.canvasContainer} ref={CanvasRef}>
          <canvas ref={CanvasRef} id="myCanvas" width={1000} height={1000} />
        </div>

        <StickerDrawer
          newJot={newJot}
          selectTool={pickSelectTool}
          importSVG={importSVG}
          show={currentTool === "Sticker"}
          showButton={showButton}
          updateHistoryState={updateState}
          hide={() => selectTool("")}
        />
        <TextEdit
          toolbarRef={toolbarRef}
          newJot={newJot}
          ref={textEditor}
          position={position}
          total_rotation={totalRotation}
          viewPort={viewport}
          selectTool={pickSelectTool}
          createText={createText}
          currentTool={currentTool}
          text_content={textContent}
          updateHistoryState={updateState}
          canvasBounds={canvasBounds}
        />



      </div>
    );
  }
);

const mapDispatchToProps = {
  selectTool,
  selectToolBar,
  selectToolDrawer,
  hideDrawer,
  showDrawer,
  changeSettings,
  updateHistoryState,
  undo: ActionCreators.undo,
  redo: ActionCreators.redo,
  jump: ActionCreators.jump,
};

const mapStateToProps = (state) => ({
  currentTool: state.tools.currentTool,
  canvasState: state.undo,
});

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

export default ConnectedApp;

const styles = {
  app: {
    backgroundColor: "transparent",
    padding: 0,
    height: window.innerHeight,
  },
  textEditor: {
    outlineWidth: 1,

    // pointerEvents: 'none',
    backgroundColor: "transparent",
    color: "white",
    opacity: 1,
    position: "absolute",
    fontWeight: "bold",
    fontSize: "38px",
    textAlign: "center",
    lineHeight: 1.22,
  },
  canvasContainer: {
    textAlign: "center",
    position: "relative",
    paddingBottom: "97%",
    height: "0",
    width: "97%",
    marginTop: 100,
    marginLeft: "auto",
    marginRight: "auto",
  },
};
