export {default as emojis_01} from './emojis/emojis_01.svg';
export {default as emojis_02} from './emojis/emojis_02.svg';
export {default as emojis_03} from './emojis/emojis_03.svg';
export {default as emojis_04} from './emojis/emojis_04.svg';
export {default as emojis_05} from './emojis/emojis_05.svg';
export {default as emojis_06} from './emojis/emojis_06.svg';
export {default as emojis_07} from './emojis/emojis_07.svg';
export {default as emojis_08} from './emojis/emojis_08.svg';
export {default as emojis_09} from './emojis/emojis_09.svg';
export {default as emojis_10 } from './emojis/emojis_10.svg';
export {default as emojis_11} from './emojis/emojis_11.svg';
export {default as emojis_12} from './emojis/emojis_12.svg';
export {default as emojis_13} from './emojis/emojis_13.svg';
export {default as emojis_14} from './emojis/emojis_14.svg';
export {default as emojis_15} from './emojis/emojis_15.svg';
export {default as emojis_16} from './emojis/emojis_16.svg';
export {default as emojis_17} from './emojis/emojis_17.svg';
export {default as emojis_18} from './emojis/emojis_18.svg';
export {default as emojis_19} from './emojis/emojis_19.svg';
export {default as emojis_20} from './emojis/emojis_20.svg';
export {default as emojis_21} from './emojis/emojis_21.svg';
export {default as emojis_22} from './emojis/emojis_22.svg';
export {default as emojis_23} from './emojis/emojis_23.svg';
export {default as emojis_24} from './emojis/emojis_24.svg';
export {default as emojis_25} from './emojis/emojis_25.svg';
export {default as emojis_26} from './emojis/emojis_26.svg';
export {default as emojis_27} from './emojis/emojis_27.svg';
export { default as emojis_28 } from './emojis/emojis_28.svg';

export {default as emojis2_01} from './emojis2/emojis2_01.svg';
export {default as emojis2_02} from './emojis2/emojis2_02.svg';
export {default as emojis2_03} from './emojis2/emojis2_03.svg';
export {default as emojis2_04} from './emojis2/emojis2_04.svg';
export {default as emojis2_05} from './emojis2/emojis2_05.svg';
export {default as emojis2_06} from './emojis2/emojis2_06.svg';
export {default as emojis2_07} from './emojis2/emojis2_07.svg';
export {default as emojis2_08} from './emojis2/emojis2_08.svg';
export {default as emojis2_09} from './emojis2/emojis2_09.svg';
export {default as emojis2_10 } from './emojis2/emojis2_10.svg';
export {default as emojis2_11} from './emojis2/emojis2_11.svg';
export {default as emojis2_12} from './emojis2/emojis2_12.svg';
export {default as emojis2_13} from './emojis2/emojis2_13.svg';
export {default as emojis2_14} from './emojis2/emojis2_14.svg';
export {default as emojis2_15} from './emojis2/emojis2_15.svg';
export {default as emojis2_16} from './emojis2/emojis2_16.svg';
export {default as emojis2_17} from './emojis2/emojis2_17.svg';
export {default as emojis2_18} from './emojis2/emojis2_18.svg';
export {default as emojis2_19} from './emojis2/emojis2_19.svg';
export {default as emojis2_20} from './emojis2/emojis2_20.svg';
export {default as emojis2_21} from './emojis2/emojis2_21.svg';
export {default as emojis2_22} from './emojis2/emojis2_22.svg';
export {default as emojis2_23} from './emojis2/emojis2_23.svg';
export {default as emojis2_24} from './emojis2/emojis2_24.svg';
export {default as emojis2_25} from './emojis2/emojis2_25.svg';
export {default as emojis2_26} from './emojis2/emojis2_26.svg';
export {default as emojis2_27} from './emojis2/emojis2_27.svg';
export {default as emojis2_28} from './emojis2/emojis2_28.svg';


export {default as parts_01} from './parts/parts_01.svg';
export {default as parts_02} from './parts/parts_02.svg';
export {default as parts_03} from './parts/parts_03.svg';
export {default as parts_04} from './parts/parts_04.svg';
export {default as parts_05} from './parts/parts_05.svg';
export {default as parts_06} from './parts/parts_06.svg';
export {default as parts_07} from './parts/parts_07.svg';
export {default as parts_08} from './parts/parts_08.svg';
export {default as parts_09} from './parts/parts_09.svg';
export {default as parts_10} from './parts/parts_10.svg';
export { default as parts_11 } from './parts/parts_11.svg';
export {default as parts_12} from './parts/parts_12.svg';
export {default as parts_13} from './parts/parts_13.svg';
export {default as parts_14} from './parts/parts_14.svg';
export {default as parts_15} from './parts/parts_15.svg';
export { default as parts_16 } from './parts/parts_16.svg';
export {default as parts_17} from './parts/parts_17.svg';
export {default as parts_18} from './parts/parts_18.svg';
export {default as parts_19} from './parts/parts_19.svg';
export {default as parts_20} from './parts/parts_20.svg';
export {default as parts_21} from './parts/parts_21.svg';
export {default as parts_22} from './parts/parts_22.svg';
export {default as parts_23} from './parts/parts_23.svg';
export {default as parts_24} from './parts/parts_24.svg';
export {default as parts_25} from './parts/parts_25.svg';
export {default as parts_26} from './parts/parts_26.svg';
export {default as parts_27} from './parts/parts_27.svg';
export { default as parts_28 } from './parts/parts_28.svg';


export {default as hands_01} from './hands/hands_01.svg';
export {default as hands_02} from './hands/hands_02.svg';
export {default as hands_03} from './hands/hands_03.svg';
export {default as hands_04} from './hands/hands_04.svg';
export {default as hands_05} from './hands/hands_05.svg';
export {default as hands_06} from './hands/hands_06.svg';
export {default as hands_07} from './hands/hands_07.svg';
export {default as hands_08} from './hands/hands_08.svg';
export {default as hands_09} from './hands/hands_09.svg';
export {default as hands_10} from './hands/hands_10.svg';
export { default as hands_11 } from './hands/hands_11.svg';
export {default as hands_12} from './hands/hands_12.svg';
export {default as hands_13} from './hands/hands_13.svg';
export {default as hands_14} from './hands/hands_14.svg';
export {default as hands_15} from './hands/hands_15.svg';
export { default as hands_16 } from './hands/hands_16.svg';
export {default as hands_17} from './hands/hands_17.svg';
export {default as hands_18} from './hands/hands_18.svg';
export {default as hands_19} from './hands/hands_19.svg';
export {default as hands_20} from './hands/hands_20.svg';
export {default as hands_21} from './hands/hands_21.svg';
export {default as hands_22} from './hands/hands_22.svg';
export {default as hands_23} from './hands/hands_23.svg';
export {default as hands_24} from './hands/hands_24.svg';
export {default as hands_25} from './hands/hands_25.svg';
export {default as hands_26} from './hands/hands_26.svg';
export {default as hands_27} from './hands/hands_27.svg';
export { default as hands_28 } from './hands/hands_28.svg';


export {default as bursts_01} from './bursts/bursts_01.svg';
export {default as bursts_02} from './bursts/bursts_02.svg';
export {default as bursts_03} from './bursts/bursts_03.svg';
export {default as bursts_04} from './bursts/bursts_04.svg';
export {default as bursts_05} from './bursts/bursts_05.svg';
export {default as bursts_06} from './bursts/bursts_06.svg';
export {default as bursts_07} from './bursts/bursts_07.svg';
export {default as bursts_08} from './bursts/bursts_08.svg';
export {default as bursts_09} from './bursts/bursts_09.svg';
export {default as bursts_10} from './bursts/bursts_10.svg';
export { default as bursts_11 } from './bursts/bursts_11.svg';
export {default as bursts_12} from './bursts/bursts_12.svg';
export {default as bursts_13} from './bursts/bursts_13.svg';
export {default as bursts_14} from './bursts/bursts_14.svg';
export {default as bursts_15} from './bursts/bursts_15.svg';
export { default as bursts_16 } from './bursts/bursts_16.svg';
export {default as bursts_17} from './bursts/bursts_17.svg';
export {default as bursts_18} from './bursts/bursts_18.svg';
export {default as bursts_19} from './bursts/bursts_19.svg';
export {default as bursts_20} from './bursts/bursts_20.svg';
export {default as bursts_21} from './bursts/bursts_21.svg';
export {default as bursts_22} from './bursts/bursts_22.svg';
export {default as bursts_23} from './bursts/bursts_23.svg';
export {default as bursts_24} from './bursts/bursts_24.svg';
export {default as bursts_25} from './bursts/bursts_25.svg';
export {default as bursts_26} from './bursts/bursts_26.svg';
export {default as bursts_27} from './bursts/bursts_27.svg';
export { default as bursts_28 } from './bursts/bursts_28.svg';


export {default as objects_01} from './objects/objects_01.svg';
export {default as objects_02} from './objects/objects_02.svg';
export {default as objects_03} from './objects/objects_03.svg';
export {default as objects_04} from './objects/objects_04.svg';
export {default as objects_05} from './objects/objects_05.svg';
export {default as objects_06} from './objects/objects_06.svg';
export {default as objects_07} from './objects/objects_07.svg';
export {default as objects_08} from './objects/objects_08.svg';
export {default as objects_09} from './objects/objects_09.svg';
export {default as objects_10} from './objects/objects_10.svg';
export { default as objects_11 } from './objects/objects_11.svg';
export {default as objects_12} from './objects/objects_12.svg';
export {default as objects_13} from './objects/objects_13.svg';
export {default as objects_14} from './objects/objects_14.svg';
export {default as objects_15} from './objects/objects_15.svg';
export { default as objects_16 } from './objects/objects_16.svg';
export {default as objects_17} from './objects/objects_17.svg';
export {default as objects_18} from './objects/objects_18.svg';
export {default as objects_19} from './objects/objects_19.svg';
export {default as objects_20} from './objects/objects_20.svg';
export {default as objects_21} from './objects/objects_21.svg';
export {default as objects_22} from './objects/objects_22.svg';
export {default as objects_23} from './objects/objects_23.svg';
export {default as objects_24} from './objects/objects_24.svg';
export {default as objects_25} from './objects/objects_25.svg';
export {default as objects_26} from './objects/objects_26.svg';
export {default as objects_27} from './objects/objects_27.svg';
export { default as objects_28 } from './objects/objects_28.svg';


export {default as objects2_01} from './objects2/objects2_01.svg';
export {default as objects2_02} from './objects2/objects2_02.svg';
export {default as objects2_03} from './objects2/objects2_03.svg';
export {default as objects2_04} from './objects2/objects2_04.svg';
export {default as objects2_05} from './objects2/objects2_05.svg';
export {default as objects2_06} from './objects2/objects2_06.svg';
export {default as objects2_07} from './objects2/objects2_07.svg';
export {default as objects2_08} from './objects2/objects2_08.svg';
export {default as objects2_09} from './objects2/objects2_09.svg';
export {default as objects2_10} from './objects2/objects2_10.svg';
export { default as objects2_11 } from './objects2/objects2_11.svg';
export {default as objects2_12} from './objects2/objects2_12.svg';
export {default as objects2_13} from './objects2/objects2_13.svg';
export {default as objects2_14} from './objects2/objects2_14.svg';
export {default as objects2_15} from './objects2/objects2_15.svg';
export { default as objects2_16 } from './objects2/objects2_16.svg';
export {default as objects2_17} from './objects2/objects2_17.svg';
export {default as objects2_18} from './objects2/objects2_18.svg';
export {default as objects2_19} from './objects2/objects2_19.svg';
export {default as objects2_20} from './objects2/objects2_20.svg';
export {default as objects2_21} from './objects2/objects2_21.svg';
export {default as objects2_22} from './objects2/objects2_22.svg';
export {default as objects2_23} from './objects2/objects2_23.svg';
export {default as objects2_24} from './objects2/objects2_24.svg';
export {default as objects2_25} from './objects2/objects2_25.svg';
export {default as objects2_26} from './objects2/objects2_26.svg';
export {default as objects2_27} from './objects2/objects2_27.svg';
export { default as objects2_28 } from './objects2/objects2_28.svg';

export {default as shapes_01} from './shapes/shapes_01.svg';
export {default as shapes_02} from './shapes/shapes_02.svg';
export {default as shapes_03} from './shapes/shapes_03.svg';
export {default as shapes_04} from './shapes/shapes_04.svg';
export {default as shapes_05} from './shapes/shapes_05.svg';
export {default as shapes_06} from './shapes/shapes_06.svg';
export {default as shapes_07} from './shapes/shapes_07.svg';
export {default as shapes_08} from './shapes/shapes_08.svg';
export {default as shapes_09} from './shapes/shapes_09.svg';
export {default as shapes_10} from './shapes/shapes_10.svg';
export { default as shapes_11 } from './shapes/shapes_11.svg';
export {default as shapes_12} from './shapes/shapes_12.svg';
export {default as shapes_13} from './shapes/shapes_13.svg';
export {default as shapes_14} from './shapes/shapes_14.svg';
export {default as shapes_15} from './shapes/shapes_15.svg';
export { default as shapes_16 } from './shapes/shapes_16.svg';
export {default as shapes_17} from './shapes/shapes_17.svg';
export {default as shapes_18} from './shapes/shapes_18.svg';
export {default as shapes_19} from './shapes/shapes_19.svg';
export {default as shapes_20} from './shapes/shapes_20.svg';
export {default as shapes_21} from './shapes/shapes_21.svg';
export {default as shapes_22} from './shapes/shapes_22.svg';
export {default as shapes_23} from './shapes/shapes_23.svg';
export {default as shapes_24} from './shapes/shapes_24.svg';
export {default as shapes_25} from './shapes/shapes_25.svg';
export {default as shapes_26} from './shapes/shapes_26.svg';
export {default as shapes_27} from './shapes/shapes_27.svg';
export { default as shapes_28 } from './shapes/shapes_28.svg';

export {default as speech_00} from './speech/speech_00.svg';
export {default as speech_01} from './speech/speech_01.svg';
export {default as speech_02} from './speech/speech_02.svg';
export {default as speech_03} from './speech/speech_03.svg';
export {default as speech_04} from './speech/speech_04.svg';
export {default as speech_05} from './speech/speech_05.svg';
export {default as speech_06} from './speech/speech_06.svg';
export {default as speech_07} from './speech/speech_07.svg';
export {default as speech_08} from './speech/speech_08.svg';
export {default as speech_09} from './speech/speech_09.svg';
export {default as speech_10} from './speech/speech_10.svg';
export { default as speech_11 } from './speech/speech_11.svg';
export {default as speech_12} from './speech/speech_12.svg';
export {default as speech_13} from './speech/speech_13.svg';
export {default as speech_14} from './speech/speech_14.svg';
export {default as speech_15} from './speech/speech_15.svg';
export { default as speech_16 } from './speech/speech_16.svg';
export {default as speech_17} from './speech/speech_17.svg';
export {default as speech_18} from './speech/speech_18.svg';
export {default as speech_19} from './speech/speech_19.svg';
export {default as speech_20} from './speech/speech_20.svg';
export {default as speech_21} from './speech/speech_21.svg';
export {default as speech_22} from './speech/speech_22.svg';
export {default as speech_23} from './speech/speech_23.svg';
export {default as speech_24} from './speech/speech_24.svg';
export {default as speech_25} from './speech/speech_25.svg';
export {default as speech_26} from './speech/speech_26.svg';
export {default as speech_27} from './speech/speech_27.svg';



export {default as google_00} from './google/google_00.svg';
export {default as google_01} from './google/google_01.svg';
export {default as google_02} from './google/google_02.svg';
export {default as google_03} from './google/google_03.svg';
export {default as google_04} from './google/google_04.svg';
export {default as google_05} from './google/google_05.svg';
export {default as google_06} from './google/google_06.svg';
export {default as google_07} from './google/google_07.svg';
export {default as google_08} from './google/google_08.svg';
export {default as google_09} from './google/google_09.svg';
export {default as google_10} from './google/google_10.svg';
export { default as google_11 } from './google/google_11.svg';
export {default as google_12} from './google/google_12.svg';
export {default as google_13} from './google/google_13.svg';
export {default as google_14} from './google/google_14.svg';
export {default as google_15} from './google/google_15.svg';
export { default as google_16 } from './google/google_16.svg';
export {default as google_17} from './google/google_17.svg';
export {default as google_18} from './google/google_18.svg';
export {default as google_19} from './google/google_19.svg';
export {default as google_20} from './google/google_20.svg';
export {default as google_21} from './google/google_21.svg';
export {default as google_22} from './google/google_22.svg';
export {default as google_23} from './google/google_23.svg';
export {default as google_24} from './google/google_24.svg';
export {default as google_25} from './google/google_25.svg';
export {default as google_26} from './google/google_26.svg';
export {default as google_27} from './google/google_27.svg';


export const emojis = [
    'emojis_01',
    'emojis_02',
    'emojis_03',
    'emojis_04',
    'emojis_05',
    'emojis_06',
    'emojis_07',
    'emojis_08',
    'emojis_09',
    'emojis_10',
    'emojis_11',
    'emojis_12',
    'emojis_13',
    'emojis_14',
    'emojis_15',
    'emojis_16',
    'emojis_17',
    'emojis_18',
    'emojis_19',
    'emojis_20',
    'emojis_21',
    'emojis_22',
    'emojis_23',
    'emojis_24',
    'emojis_25',
    'emojis_26',
    'emojis_27',
    'emojis_28'
];

export const emojis2 = [
    'emojis2_01',
    'emojis2_02',
    'emojis2_03',
    'emojis2_04',
    'emojis2_05',
    'emojis2_06',
    'emojis2_07',
    'emojis2_08',
    'emojis2_09',
    'emojis2_10',
    'emojis2_11',
    'emojis2_12',
    'emojis2_13',
    'emojis2_14',
    'emojis2_15',
    'emojis2_16',
    'emojis2_17',
    'emojis2_18',
    'emojis2_19',
    'emojis2_20',
    'emojis2_21',
    'emojis2_22',
    'emojis2_23',
    'emojis2_24',
    'emojis2_25',
    'emojis2_26',
    'emojis2_27',
    'emojis2_28'
];

export const objects = [
    'objects_01',
    'objects_02',
    'objects_03',
    'objects_04',
    'objects_05',
    'objects_06',
    'objects_07',
    'objects_08',
    'objects_09',
    'objects_10',
    'objects_11',
    'objects_12',
    'objects_13',
    'objects_14',
    'objects_15',
    'objects_16',
    'objects_17',
    'objects_18',
    'objects_19',
    'objects_20',
    'objects_21',
    'objects_22',
    'objects_23',
    'objects_24',
    'objects_25',
    'objects_26',
    'objects_27',
    'objects_28'
];

export const objects2 = [
    'objects2_01',
    'objects2_02',
    'objects2_03',
    'objects2_04',
    'objects2_05',
    'objects2_06',
    'objects2_07',
    'objects2_08',
    'objects2_09',
    'objects2_10',
    'objects2_11',
    'objects2_12',
    'objects2_13',
    'objects2_14',
    'objects2_15',
    'objects2_16',
    'objects2_17',
    'objects2_18',
    'objects2_19',
    'objects2_20',
    'objects2_21',
    'objects2_22',
    'objects2_23',
    'objects2_24',
    'objects2_25',
    'objects2_26',
    'objects2_27',
    'objects2_28'
];


export const parts = [
    'parts_01',
    'parts_02',
    'parts_03',
    'parts_04',
    'parts_05',
    'parts_06',
    'parts_07',
    'parts_08',
    'parts_09',
    'parts_10',
    'parts_11',
    'parts_12',
    'parts_13',
    'parts_14',
    'parts_15',
    'parts_16',
    'parts_17',
    'parts_18',
    'parts_19',
    'parts_20',
    'parts_21',
    'parts_22',
    'parts_23',
    'parts_24',
    'parts_25',
    'parts_26',
    'parts_27',
    'parts_28'
];


export const shapes = [
    'shapes_01',
    'shapes_02',
    'shapes_03',
    'shapes_04',
    'shapes_05',
    'shapes_06',
    'shapes_07',
    'shapes_08',
    'shapes_09',
    'shapes_10',
    'shapes_11',
    'shapes_12',
    'shapes_13',
    'shapes_14',
    'shapes_15',
    'shapes_16',
    'shapes_17',
    'shapes_18',
    'shapes_19',
    'shapes_20',
    'shapes_21',
    'shapes_22',
    'shapes_23',
    'shapes_24',
    'shapes_25',
    'shapes_26',
    'shapes_27',
    'shapes_28'
];

export const speech = [
    'speech_00',
    'speech_01',
    'speech_02',
    'speech_03',
    'speech_04',
    'speech_05',
    'speech_06',
    'speech_07',
    'speech_08',
    'speech_09',
    'speech_10',
    'speech_11',
    'speech_12',
    'speech_13',
    'speech_14',
    'speech_15',
    'speech_16',
    'speech_17',
    'speech_18',
    'speech_19',
    'speech_20',
    'speech_21',
    'speech_22',
    'speech_23',
    'speech_24',
    'speech_25',
    'speech_26',
    'speech_27'
];

export const bursts = [
    'bursts_01',
    'bursts_02',
    'bursts_03',
    'bursts_04',
    'bursts_05',
    'bursts_06',
    'bursts_07',
    'bursts_08',
    'bursts_09',
    'bursts_10',
    'bursts_11',
    'bursts_12',
    'bursts_13',
    'bursts_14',
    'bursts_15',
    'bursts_16',
    'bursts_17',
    'bursts_18',
    'bursts_19',
    'bursts_20',
    'bursts_21',
    'bursts_22',
    'bursts_23',
    'bursts_24',
    'bursts_25',
    'bursts_26',
    'bursts_27',
    'bursts_28'
];

export const hands = [
    'hands_01',
    'hands_02',
    'hands_03',
    'hands_04',
    'hands_05',
    'hands_06',
    'hands_07',
    'hands_08',
    'hands_09',
    'hands_10',
    'hands_11',
    'hands_12',
    'hands_13',
    'hands_14',
    'hands_15',
    'hands_16',
    'hands_17',
    'hands_18',
    'hands_19',
    'hands_20',
    'hands_21',
    'hands_22',
    'hands_23',
    'hands_24',
    'hands_25',
    'hands_26',
    'hands_27',
    'hands_28'
];



export const google = [
    'google_00',
    'google_01',
    'google_02',
    'google_03',
    'google_04',
    'google_05',
    'google_06',
    'google_07',
    'google_08',
    'google_09',
    'google_10',
    'google_11',
    'google_12',
    'google_13',
    'google_14',
    'google_15',
    'google_16',
    'google_17',
    'google_18',
    'google_19',
    'google_20',
    'google_21',
    'google_22',
    'google_23',
    'google_24',
    'google_25',
    'google_26',
    'google_27'
];