import React, { useState } from "react";
import paper, { Tool, Path } from "paper-jsdom";
import * as Tools from "../Styled/Tools";

const Draw = ({
  updateHistoryState,
  selectTool,
  selected
}) => {
  const [drawing, setDrawing] = useState(false);

  let tool = new Tool();
  let path;

  const activateTool = () => {
    let last_tool = paper.tool;
    paper.tool.emit("newTool", null);
    if (last_tool) {
      last_tool.remove();
    }
    tool.on("mousedown", onMouseDown);
    tool.on("mousedrag", onMouseDrag);
    tool.on("mouseup", onMouseUp);
    tool.activate();
  };

  const onMouseDown = event => {
    setDrawing(!drawing);
    path = new Path();
    path.set({ objectType: "path" });
    path.set({ topLevel: true });
    path.strokeColor = "#00000";
    path.strokeScaling = false;
    path.strokeWidth = 2;
    path.strokeCap = "round";
    path.strokeJoin = "round";
    path.fillColor = "#ffffff";
    path.fillColor.alpha = 0.0001;
    path.name = "mark";
    path.add(event.point);
  };

  const onMouseDrag = event => {
    //setTest("drag");
    path.add(event.point);
  };

  const onMouseUp = event => {
    //path.flatten(5);
    path.simplify(1);

    // path.smooth()
    updateHistoryState();
  };

  return (
    <>
      <Tools.Select
        id="Draw"
        selected={selected}
        onClick={() => [activateTool(), selectTool("Draw")]}
      >
        <span className="icon-draw" />
      </Tools.Select>
    </>
  );
};

export default Draw;
