import React, { Component } from 'react';
import * as Tools from "../Styled/Tools";


  
class Redo extends Component {

  constructor(props){
    super(props)
    this.state ={
      drawing: false
    }
  }

  activateTool(){
    this.props.redoState();
  }


  render() {
    let disabled = !this.props.currentState.future.length;
    return (
      <Tools.Tap id="Redo" onClick={() => !disabled  && this.activateTool()} disabled={disabled}>
         <span className="icon icon-redo"></span>
      </Tools.Tap>
    );
  }
}

export default Redo