import React, { Component } from "react";
import paper from "paper-jsdom";
import * as Tools from "../Styled/Tools";

const styles = {
  icon: {
    height: 70,
    width: 70,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  selected: {
    background: "#e3e3e3"
  }
};

class Clear extends Component {
  activateTool() {
    console.log("deleting...");
    this.props.clearJot();
    paper.project.activeLayer.removeChildren();
  }

  render() {
    return (
      <React.Fragment>
        <Tools.Select
          background={"grey"}
          id="Clear"
          selected={this.props.selected}
          onClick={() => this.activateTool()}
        >
          <span className="icon-trash" style={{color: "white"}}/>
        </Tools.Select>
      </React.Fragment>
    );
  }
}

export default Clear;
