import React, { useCallback, useState, useEffect } from "react";
// import { CSSTransitionGroup } from "react-transition-group"; // ES6

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import * as Stickers from "../Stickers";
import * as Toolbar from "../Styled/ToolBar";
import {
  StickerGridContainer,
  CarouselSlide,
  StickerContainer,
  StickerImage
} from "../Styled/ToolBar";
import * as Tools from "../Styled/Tools";

const StickerGrids = {
  default: [
    "emojis",
    "emojis2",
    "hands",
    "speech",
    "bursts",
    "objects",
    "objects2",
    "parts",
    "shapes"
  ],
  google: ["google"]
};

const StickerGrid = React.memo(props => {
  const {
    repo,
    updateHistoryState,
    selectTool,
    importSVG,
    showButton,
    stickerGroup
  } = props;

  const handleClick = file => {
    fetch(`${process.env.PUBLIC_URL}/${repo}/${file}.svg`)
      .then(r => r.text())
      .then(text => {
        showButton();
        importSVG(text);
      });
  };

  return (
    <StickerGridContainer>
      {Stickers[stickerGroup].map((sticker, index) => {
        return (
          <StickerContainer key={index}>
            <StickerImage
              key={index}
              onClick={() => handleClick(`${stickerGroup}/${sticker}`)}
            >
              <img src={Stickers[sticker]} />
            </StickerImage>
          </StickerContainer>
        );
      })}
    </StickerGridContainer>
  );
});

const StickerDrawer = React.memo(
  ({ show, selectTool, importSVG, showButton, updateHistoryState }) => {
    const [index, setIndex] = useState(0);
    const [repo, setRepo] = useState("stickers");
    const [grid, setGrid] = useState("default");

    const memoShowButton = useCallback(() => {
      showButton();
    }, []);

    const memoSelectTool = useCallback(() => {
      selectTool();
    }, []);

    const memoImportSVG = useCallback(text => {
      importSVG(text).then(_ => {
        selectTool();
        updateHistoryState();
      });
    }, []);

    const onChange = e => {
      setIndex(e);
    };


    useEffect(() => {
      console.log("test");

    
    }, []);

    useEffect(() => {

      let url = window.location.search;
      var regex = /[?&]([^=#]+)=([^&#]*)/g,
        params = {},
        match;
      while ((match = regex.exec(url))) {
        params[match[1]] = match[2];
      }

      if(params.info){
        let urlparams = JSON.parse(decodeURI(params.info));
        let customurl =  urlparams.url;
          if (customurl) {
          setRepo(customurl);
          setGrid(customurl);
        }
      }
    
    }, []);

    let visibility = show ? 0 : -2000;
    return StickerGrids[grid] ? (
      <div
        style={{
          left: visibility,
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%"
        }}
      >
        <Toolbar.Drawer className={"drawer"}>
          <Carousel
            style={{ backgroundColor: "red" }}
            showArrows={false}
            emulateTouch
            showStatus={false}
            showThumbs={false}
            selectedItem={index}
            onChange={onChange}
          >
            {StickerGrids[grid].map(stickerGroup => (
              <CarouselSlide key={stickerGroup}>
                <StickerGrid
                  repo={repo}
                  stickerGroup={stickerGroup}
                  selectTool={memoSelectTool}
                  importSVG={memoImportSVG}
                  showButton={memoShowButton}
                />
              </CarouselSlide>
            ))}
          </Carousel>
          }
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "white"
            }}
          >
            <Tools.Select
              id="Draw"
              background={"second"}
              onClick={() => {
                selectTool(null);
                showButton();
              }}
            >
              <span style={{ color: "white" }} className="icon-cancel" />
            </Tools.Select>
          </div>
        </Toolbar.Drawer>
      </div>
    ) : null;
  }
);

export default StickerDrawer;
