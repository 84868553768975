import React, { Component } from "react";
import * as Tools from "../Styled/Tools";


class SvgSymbol extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTarget: null,
      currentEventPoint: null
    };
  }

  activateTool() {

    setTimeout(
      () =>
      window.ReactNativeWebView && window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "ButtonHide", message: 0 })
        ),
      50
    );
    
    // let last_tool = paper.tool;
    // console.log(last_tool);
    // console.log(paper.tool);

    // paper.tool.emit("newTool", null);
    // if (last_tool) {
    //   last_tool.remove();
    // }
  }


  render() {
    let {selectTool, openStickers, selected} = this.props;
    return (
      <Tools.Select id="Sticker" selected={selected} onClick={() => [openStickers(), selectTool("Sticker"), this.activateTool()]}>
        <span className="icon-stickers" />
      </Tools.Select>
    );
  }
}

export default SvgSymbol;
