import React, { useEffect, useState, useRef, forwardRef } from "react";
import ContentEditable from "./contentEditable";

import useResizeAware from "react-resize-aware";

let timer = null;

const ResizeDiv = forwardRef((props, componentEl) => {
  let {
    fontFamily,
    margin,
    align,
    top,
    setBounds,
    width: containerWidth,
    height: containerHeight,
    setFontSize,
    fontsize,
    setTextContent,
    textContent,
    opacity,
    fontMenu
  } = props;
  const [widthSet, setWidth] = useState(false);
  const [visible, setVisibility] = useState(0);
  const [resizeListener, sizes] = useResizeAware();
  let { width, height } = sizes;
  const inputEl = useRef(null);

  const setBoundsCallback = React.useCallback(() => {
    setBounds(inputEl.current.getBoundingClientRect());
}, [setBounds]);

  const setTextContentCallback = React.useCallback((value) => {
  setTextContent(value);
}, [setTextContent]);
  
  const handleChange = e => {
    setBoundsCallback(inputEl.current.getBoundingClientRect());
    setTextContentCallback(e.target.value);
  };
  
  React.useEffect(() => {
    inputEl.current.focus();
  }, [])

  React.useEffect(() => {

    setBounds(inputEl.current.getBoundingClientRect());

    console.log("THIS", height, containerHeight);

    if (height > containerHeight - margin * 2) {
      setVisibility(0);
      setFontSize(fontsize * 0.8);
    }
    if (height < containerHeight - margin * 2) {
      setWidth(false);
    }

    if (height < containerHeight / 2 && fontsize < 38) {
      if (!widthSet) {
        setVisibility(0);
        setFontSize(fontsize * 1.2);
      }
    }

    if (width > containerWidth - margin * 2) {
      setVisibility(0);
      setFontSize(fontsize * 0.8);
      setWidth(true);
    }

    if (timer) {
      clearTimeout(timer); //cancel the previous timer.
      timer = null;
    }
    timer = setTimeout(() => setVisibility(1), 100);
  }, [width, height]);


  return (
    <div
      style={{
        opacity,
        transition: "0.5s",
        width: "100%", 
        height: containerHeight,
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        top: top,
        left: 0,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: "white",
        zIndex: 1,
      }}
    >
      <div className={"basic"} style={{position: "relative" }}>
        {resizeListener}
        <ContentEditable
          style={{
            fontFamily,
            whiteSpace: "pre",
            lineHeight: 1.21,
            position: "relative",
            display: "inline-block",
            fontSize: fontsize,
            opacity: visible,
            outline: 0,
            marginLeft: margin,
            textAlign: align,
            borderWidth: "1px",
            borderColor: "#e3e3e3",
            borderStyle: "dashed",
            padding: 5,
            caretColor : fontMenu === "entered" ? "transparent" : "blue"
          }}
          ref={componentEl}
          innerRef={inputEl}
          html={textContent} // innerHTML of the editable div
          disabled={false} // use true to disable editing
          onChange={handleChange} // handle innerHTML change
        />
      </div>
    </div>
  );
});

export default ResizeDiv;
