import { combineReducers } from 'redux';
import undoable, { excludeAction } from 'redux-undo';

import tools from './tools';
import history from './history';
import { SelectTool } from '../Actions/actionTypes';

const rootReducer = combineReducers({
  undo: undoable(history, {filter: excludeAction([SelectTool]),  ignoreInitialState:true, limit: 20}),
  tools,
});

export default rootReducer;
