import { UpdateState } from "../Actions/actionTypes";

export const initialState = {
  state: [["Layer",{"applyMatrix":false,"children":[["Path",{"applyMatrix":false,"segments":[[2,498],[2,2],[498,2],[498,498]],"closed":true,"strokeColor":[0.8902,0.8902,0.8902],"strokeWidth":1.5,"strokeScaling":false,"dashArray":[4,2]}]]}]]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UpdateState:
      return action.state;
    default:
      return state;
  }
};
