import {
  SelectTool, SelectToolBar, HideDrawer, ShowDrawer, SelectToolDrawer, ChangeSettings
} from "./actionTypes";


export const selectTool = (tool) => ({
  type: SelectTool,
  tool
});

export const selectToolBar = (tool) => ({
  type: SelectToolBar,
  tool
});

export const changeSettings = (tool, settings) => ({
  type: ChangeSettings,
  tool,
  settings
});

export const selectToolDrawer = (toolbar, tool) => ({
  type: SelectToolDrawer,
  toolbar,
  tool
});

export const hideDrawer = () => ({
  type: HideDrawer
});

export const showDrawer = () => ({
  type: ShowDrawer
});
