import Typr from "./Typr/Typr.U";
import svgpath from "svgpath";

let fonts = {
  "Casual": null,
  "Basic": null,
  "Fancy": null
}


const processFont = (path, name) => {
  fetch(path)
  .then((r) => {
    return r.blob();
  })
  .then((blob) => {
    let reader = new FileReader();
    reader.addEventListener("loadend", function () {
      fonts[name] = Typr.parse(reader.result);
    });
    reader.readAsArrayBuffer(blob);
  });
}

processFont(`${process.env.PUBLIC_URL}/Casual.otf`, "Casual")
processFont(`${process.env.PUBLIC_URL}/SingleLine.otf`, "Basic")
processFont(`${process.env.PUBLIC_URL}/Fancy.otf`, "Fancy")


export default Typr.U.stringToSVG = function (
  fontOveride,
  str,
  size,
  align,
  lineHeight,
  color,
  x,
  y,
  typescale,
  rotate,
  fontFamily="Basic"
) {
  str = str.replace(/(<br>\s*)+$/, "");
  let fontFile = fonts[fontFamily][0];
  var gls = Typr.U.stringToGlyphs(fontFile, str);

  //  gls.reverse();  // reverse if Right-to-Left
  var path = Typr.U.glyphsToPath(
    fontFile,
    gls,
    lineHeight * fontFile.head.unitsPerEm,
    align
  );
  var scale = size / fontFile.head.unitsPerEm;
  const svg = Typr.U.pathToSVG(path);

  //SINGLE LINE PATHS
  const charArray = svg.split("none");
  // const charArray = svg.split("#");
  // const transformPaths = charArray.map(path => {
  //  CLOSED PATHS
  // const processedPaths = transformPaths.map(path => {
  //   console.log(path);
  //   let cleanPath = path.replace(
  //     "M",
  //     '<path fill="none" stroke="#000000" d="M'
  //   );
  //   cleanPath = `${cleanPath}X"/>`;

  //   //cleanPath = cleanPath.replace("Z", 'Z"/>');
  //   return cleanPath;
  // });

  // Filter array for single line path data denoted by red stroke color <- needs a better method standardising single line data within font file
  // const singleLines = charArray.filter(charString => {
  //   console.log(charString);
  //   const hexCode = charString.substring(0, 6);
  //   return hexCode === "none";
  // });

  const processedPaths = charArray.map((singleLinePath) => {
    let cleanPath = singleLinePath.replace(/^[^M]+/i, "");
    let transformed = svgpath(cleanPath)
      // DON'T NEED TO FLIP SINGLE LINE
      .scale(scale * typescale, scale * typescale)
      .translate(x * typescale, y * typescale)
      .rotate(rotate)
      .round(2)
      .toString();

    return `<path fill="none" stroke="#000000" d="${transformed}"/>`;
  });
  processedPaths.reverse();
  let allPaths = processedPaths.join("");
  return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1000px" height="1000px">${allPaths}</svg>`;
};
