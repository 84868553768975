import { createStore, applyMiddleware } from "redux";
import { logger } from "redux-logger";

// import { reducer as reduxFormReducer } from 'redux-form';
import rootReducers from "./Reducers";
import { composeWithDevTools } from "redux-devtools-extension";




// const rootReducers = history =>
//   combineReducers({
//     ...allReducers,
//   });
  

let middleware =[];
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, logger];
}

// export const history = createBrowserHistory();

export default createStore(rootReducers, composeWithDevTools(
    applyMiddleware(...middleware),
    // other store enhancers if any
  ));
