import styled from "styled-components";
import { device } from "./device";

const colors = {
  first: "#4D80ff",
  second: "#FF5B4D",
  third: "#fecf39",
  fourth: "#00d1b8",
  grey: "#999999"
};

export const Tool = styled.div`
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props =>
    props.background ? colors[props.background] : "#f3f3f3"};

@media ${device.mobileM} {
    width: 40px;
    height: 40px;
  }

  @media ${device.tablet} {
    width: 55px;
    height: 55px;
    margin: 10px;

  }
`;

export const Select = styled(Tool)`
  border-width: 2px;
  border-style: solid;
  border-color: ${props => (props.selected ? "#FF5B4D" : "transparent")};
  opacity: ${props => (props.disabled ? 0.2 : 1)};
`;

export const Tap = styled(Tool)`
  border-width: 2px;
  border-color: "transparent";
  opacity: ${props => (props.disabled ? 0.2 : 1)};
`;
