import styled from "styled-components";

export const Container = styled.div`
  background: white;
  position: absolute;
  align-items: stretch;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  z-index: 1000;
  border: 1px solid #e3e3e3;
  transition: 1s;
  top: 0;
  transform: none;
  transform: translateY(
    ${({ state, animated }) => animated ? (state === "entering" || state === "entered" ? 0 : -100) : 0}px
  );
`;


export const Dropdown = styled.div`
  position: absolute;
  align-items: stretch;
  width: 100%;
  background-color: #F4F4F4;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  z-index: 9999;
  transition: 0.5s;
  top: 0px;
  left: 0;
  transform: none;
  transform: translateY(
    ${({ state, animated }) => animated ? (state === "entering" || state === "entered" ? 0 : -500) : 0}px
  );

`;


export const FontItem = styled.h2`
    padding: 20px;
    border-bottom: 1px solid #999999;
    margin: 0;
    background:  ${({ selected }) => selected  ?  "#e3e3e3" : "transparent"}
`;

export const Left = styled.div`
  align-self: stretch;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: row;
  /* justify-content: flex-start;
    position: relative;
    z-index: 100;  */
`;

export const Right = styled.div`
  align-self: stretch;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const Drawer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #e3e3e3;
  opacity: 1;
  z-index: 100000;
`;

export const CarouselSlide = styled.div`
  padding: 0;
  width: 100%;
  height: 100%;
  padding-top: 10%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #333333;
`;

export const StickerGridContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

`;

export const StickerContainer = styled.div`
  margin-bottom: 10px;
  width: calc(25% - 15px);
  height: calc(15% - 20px);
  position: relative;
  &:after {
    content: "";
    flex: auto;
  }
  
`;

export const StickerImage = styled.div`
  border-radius: 10px;
  background-color: white;
  height: 100%;
  display: flex;
  & img{
    height: 90%;
    align-self: center;
    justify-content: center;
  }
`;
