import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Transition, CSSTransition } from "react-transition-group";
import paper, { Group } from "paper-jsdom";
import ResizeDiv from "../Tools/textInput";
import * as Tools from "../Styled/Tools";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import * as Toolbar from "../Styled/ToolBar";
import TextConvert from "../TextTypr/index.js";

let myTimer;

const TextEdit = forwardRef(
  (
    { viewPort, selectTool, updateHistoryState, canvasBounds, toolbarRef },
    ref
  ) => {
    let {
      view: { width: viewWidth, height: viewHeight } = {
        viewWidth: 0,
        viewHeight: 0,
      },
      show: keyboardShow,
    } = viewPort;

    let {
      width: canvasWidth,
      height: canvasHeight,
      top: canvasTop,
      left: canvasLeft,
    } = canvasBounds;

    const [fontsize, setFontSize] = useState(24);
    const [textEditing, setTextEditing] = useState(false);
    const [textContent, setTextContent] = useState("");
    const [cancel, setCancel] = useState(false);
    const [totalRotation, setTotalRotation] = useState(null);
    const [position, setPosition] = useState(false);
    const [scale, setScale] = useState(0);
    const [bounds, setBounds] = useState({});
    const [align, setAlign] = useState("center");
    const [hitResult, sethitResult] = useState(null);
    const [toolbarHeight, setToolbarHeight] = useState(55);
    const resizeDivRef = useRef(null);
    const dummyInput = useRef(null);
    const [focusFlag, setFocusFlag] = useState(false);

    useImperativeHandle(ref, () => ({
      showEditor({
        total_rotation = 0,
        text_content,
        bounds,
        total_scaling,
        alignment = align,
        hitResult,
        fontFamily,
      }) {
        sethitResult(hitResult);
        setTextEditing(true);
        setTextContent(text_content);
        setAlign(alignment);
        setTotalRotation(total_rotation);
        bounds && setPosition({ bounds });
        setScale({ total_scaling });
      },
    }));

    const setAlignFunc = (alignment) => {
      resizeDivRef.current.keepFocus();
      setAlign(alignment);
    };

    useEffect(() => {
      if (cancel) {
        setTextEditing(false);
      }
    }, [cancel]);

    const onKeyboardHide = () => {
      if (!focusFlag) {
        !cancel && createText();
        setCancel(false);
        setTextEditing(false);
         setfontMenu("out");
      }
    };

    useEffect(() => {
      setToolbarHeight(toolbarRef.current.getBoundingClientRect().height);
      clearTimeout(myTimer);
      if (!keyboardShow) {
        myTimer = setTimeout(onKeyboardHide, 50);
      }
    }, [keyboardShow]);

    const createText = () => {
      let ratio = 500 / canvasWidth;
      let content = textContent.replace(/^[\r\n]+|\.|[\r\n]+$/g, "");
      if (content === "") {
        return false;
      }
      let pathData = TextConvert(
        null,
        content,
        fontsize * ratio,
        align,
        1.2,
        "#000000",
        0,
        0,
        1,
        0,
        fontFamily
      );

      paper.project.importSVG(pathData, {
        expandShapes: true,
        applyMatrix: true,
        insert: false,

        onLoad: (newText) => {
          newText.reverseChildren();
          newText.name = "text";
          newText.data.text_content = content;
          newText.data.fontFamily = fontFamily;
          newText.data.align = align;
          newText.position.x = (canvasWidth * ratio) / 2;
          newText.position.y = (canvasHeight * ratio) / 2;
          if (position) {
            newText.position.x = position.bounds.centerX;
            newText.position.y = position.bounds.centerY;
          }
          if (totalRotation) {
            newText.rotation = totalRotation;
            newText.data.total_rotation = totalRotation;
          }
          if (scale) {
            newText.scaling = scale.total_scaling;
            newText.data.total_scaling = scale.total_scaling;
          }
          for (var i = 0; i < newText.children.length; i++) {
            var child = newText.children[i];
            child.strokeWidth = 2;
            child.strokeCap = "round";
            child.strokeJoin = "round";
            child.strokeScaling = false;
            child.data.texttype = "text";
          }

          let importGroup = new Group(newText);
          paper.project.activeLayer.addChild(importGroup);

          hitResult && hitResult.remove();
          updateHistoryState();
          selectTool("Select");
          setTextEditing(false);
        },
      });
    };
    const onSelect = (e) => {
      setFocusFlag(true);
    };

    const showFontMenu = () => {
      dummyInput.current.focus();
      showFonts();
    }

    const [fontFamily, setFontFamily] = useState("Basic");
    const [fontMenu, setfontMenu] = useState("out");
    
    const fontSelect = (value) => {
      resizeDivRef.current.keepFocus();
      setFontFamily(value);
      setfontMenu("out")
    };

    const showFonts = () => {
      setfontMenu("entered")
    }

    return (
      <div style={{overflow: "hidden"}}>
        <Transition in={textEditing} timeout={1500}>
          {(state) => (
            <>
            <Toolbar.Container animated state={state}>
              <Toolbar.Left>
                <Tools.Select id="Font" onClick={showFontMenu}>
                  <span className="icon-typesize" />
                </Tools.Select>
                <Tools.Select id="Left" onClick={() => setAlignFunc("left")}>
                  <span className="icon-rightaligned" />
                </Tools.Select>
                <Tools.Select onClick={() => setAlignFunc("center")}>
                  <span className="icon-centered" />
                </Tools.Select>
              </Toolbar.Left>
              <Toolbar.Right>
                <Tools.Select
                  background={"fourth"}
                  onClick={() => {
                    setFocusFlag(false);
                    setTextEditing(false);
                  }}
                >
                  <span style={{ color: "white" }} className="icon-OK" />
                </Tools.Select>
                <Tools.Select
                  background={"second"}
                  onClick={() => setCancel(true)}
                >
                  <span style={{ color: "white" }} className="icon-cancel" />
                </Tools.Select>
              </Toolbar.Right>

             
              
            </Toolbar.Container>
           
            </>

          )}
        </Transition>

        <CSSTransition
          in={textEditing}
          classNames="fade"
          timeout={500}
          unmountOnExit
        >
          <div
            style={{
              transition: "0.5s",
              alignItems: "flex-start",
              display: "flex",
              justifyContent: "flex-start",
              backgroundColor: "#f3f3f3",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              position: "absolute",
              zIndex: 0,
            }}
          >
            <ResizeDiv
              fontMenu={fontMenu}
              fontFamily={fontFamily}
              ref={resizeDivRef}
              keyboardShow={keyboardShow}
              fontsize={fontsize}
              setFontSize={setFontSize}
              setBounds={setBounds}
              setTextContent={setTextContent}
              textContent={textContent}
              margin={0}
              width={viewWidth}
              height={
                viewHeight > canvasHeight
                  ? canvasHeight
                  : viewHeight - toolbarHeight
              }
              top={toolbarHeight}
              opacity={keyboardShow}
              align={align}
            />
          </div>
        </CSSTransition>
        <Toolbar.Dropdown animated state={fontMenu}>
            <Toolbar.FontItem selected={fontFamily === "Basic"} onClick={()=>fontSelect("Basic")} style={{fontFamily: "Basic"}}>Simple</Toolbar.FontItem>
            <Toolbar.FontItem selected={fontFamily === "Casual"} onClick={()=>fontSelect("Casual")} style={{fontFamily: "Casual"}}>Casual</Toolbar.FontItem>
            <Toolbar.FontItem selected={fontFamily === "Fancy"} onClick={()=>fontSelect("Fancy")} style={{fontFamily: "Fancy"}}>Curly</Toolbar.FontItem>
        </Toolbar.Dropdown>
        <input style={{pointerEvents: "none", opacity: 0}} ref={dummyInput}/>
      </div>
    );
  }
);

export default TextEdit;
