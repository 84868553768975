import React, { Component } from 'react';
import * as Tools from "../Styled/Tools";


  
class Undo extends Component {

  constructor(props){
    super(props)
    this.state ={
      drawing: false
    }
  }

  activateTool(){
   
    this.props.undoState();
  }


  render() {
    const { currentState } = this.props;
    const disabled = currentState && !currentState.past.length;
    return (
      <Tools.Tap id="Undo" onClick={() => !disabled && this.activateTool()} disabled={disabled}>
      <span className="icon icon-undo"></span>
      </Tools.Tap>
    );
  }
}

export default Undo
