import {
  SelectTool, SelectToolBar, HideDrawer, ShowDrawer, SelectToolDrawer, ChangeSettings
} from "../Actions/actionTypes";

export const initialState = {
  currentTool: false,
  currentToolBar: false,
  currentToolDrawer: false,
  drawer: false, 
  currentToolSettings: false
};

export default (state = initialState, action) => {

  switch (action.type) {
    case SelectTool:
      return {
        ...state,
        currentTool: action.tool,
      };
    case SelectToolBar:
      return {
        ...state,
        currentToolBar: action.tool,
      };
    case ChangeSettings:
      let tool = action.tool
      return {
        ...state,
          currentToolSettings: {
              ...state.currentToolSettings,
              [tool] : action.settings
          }          
      };
      case SelectToolDrawer:
      let toolbar = action.toolbar
      return {
        ...state,
          currentToolDrawer: {
              ...state.currentToolDrawer,
              [toolbar] : action.tool
          }          
      };
    case HideDrawer:
      return {
        ...state,
        drawer: false
      };
    case ShowDrawer:
      return {
        ...state,
        drawer: true
      };
    default:
      return state;
  }
};
