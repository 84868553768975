import React, { Component } from "react";
import paper, { Tool } from "paper-jsdom";
import * as Tools from "../Styled/Tools";

class Text extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textString: "",
      textArray: [],
      editing: false,
      currentGroup: null,
      currentIndex: 0,
      cursorCarrot: null,
      currentTarget: null,
      currentEventPoint: null,
      selectionRectangle: null,
      delta: {
        x: 0,
        y: 0,
      },
    };
    this.activateTool = this.activateTool.bind(this);
  }

  componentDidMount = () => {
    window.addEventListener("keydown", this.handleKeyPress, false);
  };

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyPress.bind(this));
  }

  activateTool() {
    let _this = this;
    if (paper.tool) {
      paper.tool.emit("newTool", null);
      paper.tool.remove();
    }

    let tool = new Tool();
    this.props.selectTool("text");

    window.addEventListener("transform", function (event) {
      _this.state.editing && _this.textUpdate(event.detail.item);
    });

    window.addEventListener("rotate", function (event) {
      //alert("rotation");
    });

    tool.on("newTool", function (e) {
      _this.setState({ editing: false });
    });

    tool.onMouseDown = (event) => {
      this.setState({ currentEventPoint: event.downPoint });
      _this.setState({ editing: true });

      let hitOptions = {
        stroke: true,
        fill: true,
        tolerance: 10,
      };

      let hitResult = paper.project.hitTest(event.point, hitOptions);
      let { texttype = false } = (hitResult && hitResult.item.data) || false;
      if (hitResult && texttype) {
        let { total_rotation, text_content, total_scaling, align, fontFamily } =
          hitResult.item.parent.data || null;
        let { bounds } = hitResult.item.parent;
        this.props.texteditor.current.showEditor({
          total_rotation,
          text_content,
          bounds,
          total_scaling,
          align,
          hitResult: hitResult.item.parent,
          fontFamily
        });
      } else {
        this.props.texteditor.current.showEditor({
          total_rotation: 0,
          text_content: "",
        });
      }
    };

    tool.activate();
  }

  render() {
    return (
      <React.Fragment>
        <Tools.Select
          id="Text"
          selected={this.props.selected}
          onClick={() => this.activateTool()}
        >
          <span className="icon-text" />
        </Tools.Select>
      </React.Fragment>
    );
  }
}

export default Text;
