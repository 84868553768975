import React, { Component } from "react";
import paper, {
  Tool,
  Path,
  Point,
  SymbolDefinition,
  SymbolItem,
  Group,
  Rectangle
} from "paper-jsdom";
import * as Tools from "../Styled/Tools";

const lock_svg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 29.9 29.3" style="enable-background:new 0 0 29.9 29.3;" xml:space="preserve">
<style type="text/css">
.st0{fill:none;stroke:#000000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style>
<path class="st0" d="M18.8,22.9h-8.4c-1.3,0-2.3-1-2.3-2.3v-6c0-1.3,1-2.3,2.3-2.3h8.4c1.3,0,2.3,1,2.3,2.3v6
C21.1,21.9,20.1,22.9,18.8,22.9z"/>
<path class="st0" d="M10.6,11.6V8.3c0-2.1,1.7-3.8,3.8-3.8h0c2.1,0,3.8,1.7,3.8,3.8v3.3"/>
</svg>
`;

const delete_svg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 29.9 29.3" style="enable-background:new 0 0 29.9 29.3;" xml:space="preserve">
<style type="text/css">
.st0{fill:none;stroke:#000000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style>
<line class="st0" x1="7.6" y1="8.3" x2="20.4" y2="21.2"/>
<line class="st0" x1="20.4" y1="8.3" x2="7.6" y2="21.2"/>
</svg>`;

const rotate_svg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 29.9 29.3" style="enable-background:new 0 0 29.9 29.3;" xml:space="preserve">
<style type="text/css">
.st0{fill:none;stroke:#FF0000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style>
<polyline class="st0" points="24.1,16.9 20.5,18.6 18.8,14.8 "/>
<path class="st0" d="M11.3,21.7c-1.8-0.7-3.3-2.1-4.2-4c-1.7-3.8,0-8.3,3.8-10s8.3,0,10,3.8c1,2.3,0.8,4.7-0.3,6.7"/>
</svg>`;

const scale_svg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 29.9 29.3" style="enable-background:new 0 0 29.9 29.3;" xml:space="preserve">
<style type="text/css">
.st0{fill:none;stroke:#000000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style>
<line class="st0" x1="8" y1="7" x2="22.2" y2="21.2"/>
<polyline class="st0" points="7.7,11.4 7.7,6.8 12.4,6.8 "/>
<polyline class="st0" points="22.3,16.6 22.3,21.2 17.6,21.2 "/>
</svg>
`;

const addScaleHandle = async (position, zoom) => {
  let handle1 = new Path.Circle({
    radius: window.innerWidth >= 640 ? 15 : 25,
    fillColor: "#4697F9",
    name: "scale"
  });

  return new Promise((resolve, reject) => {
    paper.project.importSVG(scale_svg, {
      onLoad: function(item) {
        item.name = "svg_colibri";
        item.strokeColor = "white";
        item.position = new Point(1, 0);
        let group = new Group([handle1, item]);
        let definition = new SymbolDefinition(group);
        let scaleSymbol = new SymbolItem(definition);
        scaleSymbol.position = new Point(position);
        scaleSymbol.name = "scale";
        scaleSymbol.opacity = 0.85;
        scaleSymbol.scaling = 1;
        resolve(scaleSymbol);
      }
    });
  });
};

const addLockHandle = async (position, zoom=2) => {
  let handle1 = new Path.Circle({
    radius: window.innerWidth >= 640 ? 15 : 25,
    fillColor: "#999999",
    name: "lock"
  });

  return new Promise((resolve, reject) => {
    paper.project.importSVG(lock_svg, {
      onLoad: function(item) {
        item.name = "lock";
        item.strokeColor = "white";
        item.position = new Point(1, 0);
        let definition = new SymbolDefinition(item);
        let lockSymbol = new SymbolItem(definition);
        lockSymbol.name = "lock";
        let group = new Group([handle1, lockSymbol]);
        group.position = new Point(position);
        group.name = "lock";
        group.opacity = 0.85;
        group.scaling = 1;

        resolve(group);
      }
    });
  });
};

const addRotateHandle = async (position, zoom = 2) => {
  console.log(window.innerWidth);
  let handle2 = new Path.Circle({
    radius: window.innerWidth >= 640 ? 15 : 25,
    fillColor: "#4697F9",
    name: "rotate"
  });

  return new Promise((resolve, reject) => {
    paper.project.importSVG(rotate_svg, {
      onLoad: function(item) {
        item.name = "svg_colibri";
        item.strokeColor = "white";
        item.position = new Point(1, 0);
        let group = new Group([handle2, item]);
        let definition = new SymbolDefinition(group);
        let rotateSymbol = new SymbolItem(definition);
        rotateSymbol.position = new Point(position);
        rotateSymbol.name = "rotate";
        rotateSymbol.opacity = 0.85;
        rotateSymbol.scaling = 1;

        resolve(rotateSymbol);
      }
    });
  });
};

const addDeleteHandle = async (position, zoom = 2) => {
  let handle1 = new Path.Circle({
    radius: window.innerWidth >= 640 ? 15 : 25,
    fillColor: "#FF6161",
    name: "delete"
  });

  return new Promise((resolve, reject) => {
    paper.project.importSVG(delete_svg, {
      onLoad: function(item) {
        item.name = "svg_colibri";
        item.strokeColor = "white";
        item.position = new Point(1, 0);
        let group = new Group([handle1, item]);
        let definition = new SymbolDefinition(group);
        let scaleSymbol = new SymbolItem(definition);
        scaleSymbol.position = new Point(position);
        scaleSymbol.name = "delete";
        scaleSymbol.opacity = 0.85;
        scaleSymbol.scaling = 1;

        resolve(scaleSymbol);
      }
    });
  });
};

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      type: null,
      nodeTool: false,
      scaleLock: true,
      rotating: false,
      dragging: false,
      currentArea: null,
      selectionRectangleScale: 1,
      selectionRectangleRotation: 0,
      scaling: false,
      rotation: 0,
      position: {},
      currentScaling: false,
      updateState: false
    };

    this.createSelectionGroup = this.createSelectionGroup.bind(this);
    this.onClick = this.onClick.bind(this);
    this.activateTool = this.activateTool.bind(this);
    this.removeSelection = this.removeSelection.bind(this);
    this.rotateItem = this.rotateItem.bind(this);
    this.scaleItem = this.scaleItem.bind(this);
  }

  componentDidMount() {}

  getCurrentSelection = () => {
    return paper.project.getItems({ name: "selection" })[0];
  };

  getCurrentSelectedItem = () => {
    return paper.project.getItems({ name: "selected" })[0];
  };

  removeSelection = (deleting = false, ungroup = false) => {
    let currentSelected;
    return new Promise((resolve, reject) => {
      let currentSelection = this.getCurrentSelection();

      if (currentSelection) {

        currentSelection.applyMatrix = true;
        currentSelected = this.getCurrentSelectedItem();

        currentSelection.remove();
        if (!deleting) {
          if (ungroup && currentSelected.data.marquee) {
            currentSelected.children.name = "unselected";
            paper.project.activeLayer.addChildren(currentSelected.removeChildren()); 
            currentSelected.remove();
          } else {
            paper.project.activeLayer.addChild(currentSelected);
            currentSelected.name = "unselected";
          }
        }
        this.setState({ selectionRectangleRotation: 0 });
        this.setState({ selectionRectangleScale: 1 });
        this.setState({ selected: false });
      }
      resolve();
    });
  };

  onClick() {
    this.props.selectTool("Select");
    this.removeSelection();
    this.activateTool();
  }

  rotateItem(event) {
    let selection = this.getCurrentSelection();
    let selected = this.getCurrentSelectedItem();

    selection.applyMatrix = false;

    const { dragging, scaling } = this.state;
    if (!dragging && !scaling) {
      let rotation =
        event.point.subtract(selection.position).angle -
        this.state.selectionRectangleRotation;
      selection.rotation = rotation;
      selected.data.current_rotation = rotation;
      this.scaleHandle.remove();
      this.lockHandle.remove();
      this.deleteHandle.remove();
      this.setState({ scaling: this.state.scaling, rotation });
    }
  }

  scaleItem(event) {
    let currentSelection = this.getCurrentSelection();
    if (currentSelection) {
      currentSelection.applyMatrix = false;
      const { scaling } = this.state;
      if (scaling) {
        this.setState({ scaling: true });

        let scaling =
          currentSelection.position.subtract(event.point).length /
          this.state.selectionRectangleScale;
        let scalingX =
          ((currentSelection.position.x - event.point.x) /
            this.state.selectionRectangleScaleX) *
            Math.cos(-currentSelection.rotation) +
          ((currentSelection.position.y - event.point.y) /
            this.state.selectionRectangleScaleY) *
            Math.sin(currentSelection.rotation);
        let scalingY =
          ((currentSelection.position.x - event.point.x) /
            this.state.selectionRectangleScaleX) *
            Math.sin(currentSelection.rotation) +
          ((currentSelection.position.y - event.point.y) /
            this.state.selectionRectangleScaleY) *
            Math.cos(currentSelection.rotation);

        let xfactor = event.point.x > currentSelection.position.x ? -1 : 1;
        let yfactor = event.point.y > currentSelection.position.y ? -1 : 1;

        let xyScale = new Point(scalingX, scalingY);
        let inverse;

        let boundingRectangle = paper.project.getItem({ name: "bounding" });

        if (this.state.scaleLock) {
          currentSelection.scaling = scaling;
          inverse = 1 / scaling;
          scalingY = scalingX;
          this.scaleHandle.scaling = inverse;
          this.scaleHandle.position = boundingRectangle.bounds.topLeft.add(
            new Point((-1 * 15) / scaling, (-1 * 15) / scaling)
          );
        } else {
          currentSelection.scaling = xyScale;
          inverse = new Point(1 / scalingX, 1 / scalingY);
          this.scaleHandle.scaling = inverse;
          this.scaleHandle.position = boundingRectangle.bounds.topLeft.add(
            new Point((-xfactor * 15) / scalingX, (-yfactor * 15) / scalingY)
          );
        }

        this.setState({ currentScaling: currentSelection.scaling });
        this.lockHandle.remove();
        this.rotateHandle.remove();
        this.deleteHandle.remove();
        this.setState({ scaling });
      }
    }
  }

  async createSelectionGroup(selection) {

      //MARQUEE
      if (Array.isArray(selection) && !selection.length) {
        return;
      }
    
    const zoom = this.props.zoom;
    if (selection) {
      if (
        selection &&
        selection.parent &&
        selection.parent.className === "Group"
      ) {
        selection = selection.parent;
      }

       //MARQUEE TOOL
       if (Array.isArray(selection)) {
        selection = new Group(selection);
        selection.data.marquee = true;
      }

      //CREATE BOUNDING RECTANGLE
      let boundingRectangle = new Path.Rectangle(
        selection.bounds.expand(10, 10)
      );
      boundingRectangle.strokeWidth = 1;
      boundingRectangle.strokeColor = "#9CDBF7";
      boundingRectangle.strokeScaling = false;
      boundingRectangle.name = "bounding";
      boundingRectangle.dashArray = [4, 2];
      boundingRectangle.fillColor = "#e3e3e3";
      boundingRectangle.fillColor.alpha = 0.02;

      //CREATE TRANSFORM HANDLES
      this.scaleHandle = await addScaleHandle(
        boundingRectangle.bounds.topLeft.add(new Point(-15, -15)),
        zoom
      );
      this.rotateHandle = await addRotateHandle(
        boundingRectangle.bounds.topRight.add(new Point(15, -15)),
        zoom
      );
      this.deleteHandle = await addDeleteHandle(
        boundingRectangle.bounds.bottomRight.add(new Point(15, 15)),
        zoom
      );
      this.lockHandle = await addLockHandle(
        boundingRectangle.bounds.bottomLeft.add(new Point(-15, 15)),
        zoom
      );
      if (this.state.scaleLock) {
        this.lockHandle.children.lock.fillColor = "#666666";
      } else {
        this.lockHandle.children.lock.fillColor = "#e3e3e3";
      }
      let group = new Group(
        selection,
        boundingRectangle,
        this.rotateHandle,
        this.scaleHandle,
        this.deleteHandle,
        this.lockHandle
      );
      selection.name = "selected";
      selection.data.current_rotation = 0;
      group.name = "selection";
      group.pivot = boundingRectangle.position;
      this.setState({ selected: true });
      this.setState({ currentArea: boundingRectangle });
    }
  }

  activateTool() {
    let _this = this;
    let clickCount = 0;
    if (paper.tool) {
      paper.tool.emit("newTool", null);
      paper.tool.remove();
    }
    this.tool = new Tool();

    this.tool.on("export", function(e) {
      _this.removeSelection();
    });

    this.tool.on("newTool", function(e) {
      _this.removeSelection();
    });

    this.removeSelection();

    let dragging = false;
    let rect;
    let path;
    // Mouse Down -
    this.tool.onMouseDown = event => {
      // debounceTimer = setTimeout(() => {
      //   dragging = true;
      // }, 150);

      var hitOptions = {
        stroke: true,
        fill: true,
        tolerance: 10
      };

      let hitResult = paper.project.activeLayer.hitTest(
        event.point,
        hitOptions
      );

      let currentSelection = _this.getCurrentSelection();
      //MARQUEE - not quite right as we'll need to fix the android dragging issue
      if (currentSelection) {
        dragging = true;
      }

      if (hitResult) {
        if (
          hitResult.item.name !== "rotate" &&
          hitResult.item.name !== "scale" &&
          hitResult.item.name !== "bounding" &&
          hitResult.item.name !== "lock" &&
          hitResult.item.name !== "delete" &&
          !_this.state.selected
        ) {
          _this.createSelectionGroup(hitResult.item);
        } else if (hitResult.item.name === "scale") {
          _this.setState({ rotating: false, scaling: true, dragging: false });
          if (_this.state.selectionRectangleScale === 1) {
            let selectionRectangleScale = currentSelection.position.subtract(
              event.point
            ).length;

            let selectionRectangleScaleX =
              currentSelection.position.x - event.point.x * 1.05;
            let selectionRectangleScaleY =
              currentSelection.position.y - event.point.y * 1.05;

            _this.setState({
              selectionRectangleScale,
              selectionRectangleScaleX,
              selectionRectangleScaleY
            });
          }
        } else if (hitResult.item.name === "rotate") {
          _this.setState({ rotating: true, scaling: false, dragging: false });

          if (_this.state.selectionRectangleRotation === 0) {
            _this.state.selectionRectangleRotation = event.point.subtract(
              currentSelection.position
            ).angle;
          }
        } else if (hitResult.item.name === "delete") {
          _this.removeSelection(true);
        } else if (hitResult.item.name === "lock") {
          _this.setState({ scaleLock: !this.state.scaleLock });
        } else if (hitResult.item.parent.name !== "selection") {
          _this.removeSelection();
          _this.createSelectionGroup(hitResult.item);
        } else {
          //DOUBLE CLICK TO SENT TO BACK
          if (clickCount > 0) {
            let item =
              paper.project.activeLayer.children["selection"].children[
                "selected"
              ];
            _this.removeSelection();
            if (paper.project.activeLayer.children.length > 1) {
              item.remove();
              item.insertBelow(paper.project.activeLayer.firstChild);
            }

            clickCount = 0;
          } else {
            clickCount++;
          }
          //_this.removeSelection();
        }
      } else if (
        currentSelection &&
        event.point.isInside(currentSelection.children["bounding"].bounds)
      ) {
        this.setState({ dragging: true });
      } else {
        this.setState({ updateState: false });
        _this.removeSelection(null, true);
      }
    };

    // Mouse Drag
    this.tool.onMouseDrag = event => {
      let selected = _this.getCurrentSelection();
      if (Array.isArray(selected) && !selected.length) {
        selected = null;
      }

      if (dragging && selected) {
        this.setState({ updateState: true });

        let selectedState = _this.state.selected;
        clickCount = 0;
        //DRAG

        if (
          selectedState &&
          selected &&
          !this.state.rotating &&
          !this.state.scaling
        ) {
          selected.position.x += event.delta.x;
          selected.position.y += event.delta.y;
        } else if (selectedState && this.state.rotating) {
          _this.rotateItem(event);
        } else if (selectedState && this.state.scaling) {
          _this.scaleItem(event);
        }
      } else {
        if (!selected) {
          rect = new Rectangle(event.downPoint, event.point);
          path = new Path.Rectangle(rect);
          path.strokeWidth = 1;
          path.strokeColor = "#9CDBF7";
          path.strokeScaling = false;
          path.name = "selectRectangle";
          path.dashArray = [4, 2];
          path.removeOnDrag();
        }
      }
    };

    // Mouse Up
    this.tool.onMouseUp = event => {
      // clearTimeout(debounceTimer);
      dragging = false;

      if (path) {
        let allItems = paper.project.activeLayer.getItems();
        let selectionGroup = allItems.filter(item => {
          return item.name !== "selectRectangle" && item.isInside(rect);
        });
        this.createSelectionGroup(selectionGroup);
        path.remove();
        path = null;
      } else {
        let selection = this.getCurrentSelectedItem();
        if (selection) {
          let total_rotation = selection.data.total_rotation;
          let totalScaling = this.state.currentScaling;

          selection.data.total_rotation = total_rotation
            ? selection.data.current_rotation + total_rotation
            : selection.data.current_rotation;

          if (totalScaling) {
            let { x: selectX, y: selectY } = selection.data.total_scaling || {
              x: 1,
              y: 1
            };
            let { x: totalX, y: totalY } = totalScaling;
            selection.data.total_scaling = new Point(
              selectX * totalX,
              selectY * totalY
            );
          }

          this.removeSelection();

          if (this.state.updateState) {
            this.props.updateHistoryState();
            this.setState({ updateState: false });
          }
          this.createSelectionGroup(selection);
        }
        this.setState({
          dragging: false,
          currentScaling: false,
          scaling: false,
          rotating: false
        });
      }
    };

    this.tool.activate();
  }

  render() {
    let { selected } = this.props;
    return (
      <Tools.Select id="Select" selected={selected} onClick={this.onClick}>
        <span className="icon-move" />
      </Tools.Select>
    );
  }
}

export default Select;
