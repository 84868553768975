import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from './App';
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import store from "./store";
import { AppContainer } from 'react-hot-loader';

const render = () => {


  
ReactDOM.render(
  <Provider store={store}>
        <AppContainer>
      <App />
      </AppContainer>

  </Provider>,
  document.getElementById("root")
);
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


  // if (!window.Intl) {
  //   require.ensure([], () => {
  //     require('intl')

  //     render();
  //   })
  // } else {
   
  // }
  render();


if (module.hot) {
  module.hot.accept('./App', () => {
    render();
  });
}