import paper, { Path, Layer, Group, Point, Size } from "paper-jsdom";

export const exportSVG = () => {
  paper.tools.map((tool) => tool.emit("export", null));
  let originalItems = paper.project.exportJSON();

  return new Promise((resolve, reject) => {
    let paths = paper.project.activeLayer.getItems({
      class: /(Shape|Path)/,
      opacity: 1,
    });

    paper.project.activeLayer.removeChildren();

    let pathPaths = paths.map((path) => {
      //path.reverse();
      path.applyMatrix = true;
      if (path.className !== "CompoundPath") {
        let newpath = path.toPath();

        if (path.parent) {
          newpath.matrix = newpath.globalMatrix;
        }
        return newpath;
      } else {
        return path;
      }
    });

    let point = new Point(2, 2);
    let size = new Size(496, 496);
    let cropLine = new Path.Rectangle(point, size);

    pathPaths.reverse();

    let cropped = pathPaths.reduce((result, child) => {
      const cropLines = (child) => {
        if (child) {
          let newpath = child.intersect(cropLine, {
            insert: false,
            trace: false,
          });
          child.remove();
          if (newpath.className === "CompoundPath") {
            newpath.children.map((child) => {
              return cropLines(child);
            });
          } else if (
            newpath.length > 0 &&
            newpath.pathData &&
            !newpath.isEmpty()
          ) {
            result.push(newpath);
          }
        }
      };
      cropLines(child);
      return result;
    }, []);
    cropLine.remove();

    let group = new Group(cropped.reverse());
    let SVG = group.exportSVG({ asString: true });
    group.remove();
    paper.project.importJSON(originalItems);
    const fullSVG = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="500" height="500">${SVG}</svg>`;
    resolve(fullSVG);
  });
};

export const cleanCurentJot = () => {
  var drawLayer = paper.project.getItems({
    name: "drawLayer",
    class: Layer,
  });
  drawLayer[0].removeChildren();
};

export const cleanBackground = () => {
  var items = paper.project.getItems({
    name: "backgroundLayer",
    class: Layer,
  });
  if (items.length) {
    items.map((item) => {
      console.log(item);
      item.remove();
    });
  }
};

export const importSVGBackground = (svg=true, wipe, reload) => {
  if (wipe) {
    cleanCurentJot();
  }

  if (reload) {
    cleanBackground();
  }

  if (svg) {
    let noviewbox = svg.replace(/viewBox="[a-zA-Z0-9:;\.\s\(\)\-\,]*"/, "");
    noviewbox = noviewbox.replace(/style="[a-zA-Z0-9:;\.\s\(\)\-\,]*"/, "");
    return new Promise((resolve) => {
      paper.project.importSVG(noviewbox, {
        expandShapes: true,
        applyMatrix: true,
        insert: false,
        onLoad: (group) => {
          group.strokeColor = "black";
          group.strokeScaling = false;
          group.strokeWidth = 2;
          group.strokeCap = "round";
          group.strokeJoin = "round";

          let importGroup = new Group(group);

          let layer = new Layer({
            name: "backgroundLayer",
            children: [importGroup],
            opacity: 0.05,
            position: new Point(250, 250),
          });

          paper.project.insertLayer(0, layer);

          resolve();
        },
        onError: (error) => {
          console.log(error);
        },
      });
    });
  }
};

export const importSVG = (svg) => {
  let noviewbox = svg.replace(/viewBox="[a-zA-Z0-9:;\.\s\(\)\-\,]*"/, "");
  noviewbox = noviewbox.replace(/style="[a-zA-Z0-9:;\.\s\(\)\-\,]*"/, "");
  return new Promise((resolve) => {
    paper.project.importSVG(noviewbox, {
      expandShapes: true,
      applyMatrix: true,
      insert: false,
      onLoad: (group) => {
        let newPaths = [];

        const iterateGroups = (group) => {
          group.children.map((item) => {
            if (
              item.className === "CompoundPath" ||
              item.className === "Group"
            ) {
              return iterateGroups(item);
            } else {
              return newPaths.push(item);
            }
          });
        };

        iterateGroups(group);

        let newWidth = group.bounds.width || 1;
        let newHeight = group.bounds.height || 1;
        let ratio = newWidth > newHeight ? 200 / newWidth : 200 / newHeight;
        newPaths.map((path) => {
          path.strokeColor = "black";
          path.strokeScaling = false;
          path.strokeWidth = 2;
          path.strokeCap = "round";
          path.strokeJoin = "round";
          path.fillColor = "#ffffff";
          path.fillColor.alpha = 0.0001;
          path.opacity = 1;
          return null;
        });
        let importGroup = new Group(newPaths);
        importGroup.scale(ratio);
        importGroup.position = new Point(250, 250);
        paper.project.activeLayer.addChild(importGroup);
        resolve();
      },
      onError: (error) => {
        console.log(error);
      },
    });
  });
};

const cropToCanvas = (paths, cropRectangle) => {
  return new Promise((resolve) => {
    let cropped = paths.reduce((result, child) => {
      const cropLines = (child) => {
        if (child) {
          let newpath = child.intersect(cropRectangle, {
            insert: false,
            trace: false,
          });
          child.remove();
          newpath.strokeColor = "black";
          if (newpath.className === "CompoundPath") {
            newpath.children.map((child) => {
              return cropLines(child);
            });
          } else if (
            newpath.length > 0 &&
            newpath.pathData &&
            !newpath.isEmpty()
          ) {
            result.push(newpath);
          }
        }
      };
      cropLines(child);
      return result;
    }, []);
    resolve(cropped);
    //resolve(paths);
  });
};

const getAllPaths = () => {
  return new Promise((resolve) => {
    let paths = paper.project.activeLayer.getItems({
      class: /(Shape|Path)/,
      opacity: 1,
    });

    paper.project.activeLayer.removeChildren();

    resolve(
      paths.map((path) => {
        path.applyMatrix = true;
        if (path.className !== "CompoundPath") {
          let newpath = path.toPath();
          if (path.parent) {
            newpath.matrix = newpath.globalMatrix;
          }
          return newpath;
        } else {
          return path;
        }
      })
    );
  });
};

// const createSVG = (json, font) => {

//   const superPromise = Promise.all(
//     json.items.map(item => {
//       let {
//         x = 0,
//         y = 0,
//         height = 300,
//         width = 300,
//         border = null,
//         svg = null,
//         text: {
//           body = null,
//           size = 14,
//           lineHeight = 1,
//           align = "left",
//           padding = 0
//         } = {}
//       } = item;

//       //Outline of box set

//       if (border && border.enable) {
//         let path = new Path.Rectangle(x, y, width, height);
//         let radius = new Size(border.radius, border.radius);
//         let boundary = new Path.Rectangle(path, radius);

//         boundary.set({
//           strokeColor: "#FF0000",
//           strokeWidth: 2
//         });

//         paper.project.activeLayer.addChild(boundary);
//       }

//       svg && importSVGFunc(svg, x, y, width, height, false, true);

//       return body &&
//         stringToSVG(
//           body,
//           size,
//           align,
//           lineHeight,
//           0,
//           0,
//           1,
//           0,
//           width - padding * 2,
//           font
//         ).then(textSVG => {

//           let textCrop = new Path.Rectangle(
//             x+padding,
//             y+padding,
//             width - padding * 2,
//             height - padding * 2
//           );

//           paper.project.importSVG(textSVG, {
//             expandShapes: true,
//             applyMatrix: true,
//             insert: false,
//             onLoad: group => {
//               group.bounds.topLeft = new Point(x+padding, y+padding);

//               let croppedText = group.getItems({
//                 class: Path,
//                 inside: textCrop.bounds
//               });
//               textCrop.remove();
//               paper.project.activeLayer.addChildren(croppedText);

//             }
//           });
//         });
//     })
//   );

//   return superPromise.then(() => {
//     return getAllPaths().then(paths => {
//       // let originalItems = paper.project.exportJSON();
//       //setup crop rectangle
//       let point = new Point(2, 2);
//       let size = new Size(996, 996);
//       let cropRectangle = new Path.Rectangle(point, size);
//       cropRectangle.strokeColor = "blue";
//       cropRectangle.strokeWidth = 1;

//       return cropToCanvas(paths, cropRectangle)
//         .then(cropped => {
//           new Group(cropped);
//           // cropRectangle.remove();
//           let SVG = paper.project.exportSVG({ asString: true });
//           paper.project.clear();
//           return SVG;
//         })
//     });
//   });
// };
